import styled, { keyframes } from "styled-components";
export const BlackBgStyles = styled.div`
  &.blackBg {
    position: fixed;
    top: 94px;
    left: 0;
    background: #00000059;
    width: 100%;
    height: 100%;
    z-index: 992;
    display: none;
    &.active {
      display: block;
    }
    @media (min-width: 1025px) {
      display: block !important;
      background: none;
      pointer-events: none;
    }
  }
`;
