import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Pretendard';
    src: url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard-dynamic-subset.min.woff2') format('woff2'),
         url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard-dynamic-subset.min.woff') format('woff');
    font-weight: 300 700;
    font-display: swap;
  }

html {
    /* Background, Box 등 사용되는 Color */
    --color-background-primary: #FFFFFF;
    --color-background-secondary: #F2EDE8;
    --color-background-tertiary: #F5F0E5;

    /* Logo Green Color */
    --color-logo-primary: #009963;

    /* Text, Icon 사용되는 Color */
    --color-label-primary: #1C140D;
    --color-label-secondary: #96704F;
    --color-label-tertiary:#A1824A;
    --color-label-none:#9ba2a8;

    /* Size */
    --font-size-xxl: 36px;
    --font-size-xl: 22px;
    --font-size-lg: 18px;
    --font-size-md: 16px;
    --font-size-sm: 14px;
    --font-size-xs: 12px;

    /* Size for screens smaller than 768px */
    --mo-font-size-xxl: 30px;
    --mo-font-size-xl: 20px;
    --mo-font-size-lg: 16px;
    --mo-font-size-md: 14px;
    --mo-font-size-sm: 12px;
    --mo-font-size-xs: 10px;

    /* Weight */
    --font-weight-bold: 700;
    --font-weight-medium: 500;
    --font-weight-light: 300;

    /* font */
    --font-family-primary: 'Pretendard', sans-serif;
  }
  
  body {
    font-family: var(--font-family-primary);
  }

  #id{position:relative}

  h1, h2, h3, h4, h5 {
    font-weight: var(--font-weight-bold);
  }
  h1 {
    font-size: var(--font-size-xxl);
  }
  h2 {
    font-size: var(--font-size-xl);
  }
  h3 {
    font-size: var(--font-size-lg);
  }
  h4 {
    font-size: var(--font-size-md);
  }
  h5 {
    font-size: var(--font-size-sm);
  }

  button{
    &:disabled{opacity: 0.8;
    cursor: default!important;;}
  }
  `;
export default GlobalStyle;

// @media (max-width: 768px) {
//   font-size: var(--mo-font-size-xs);
// }
