import React, { ReactNode } from "react";
import { ContentStyles, PageWrapperStyles } from "./styles";
import ScrollToTop from "../scrollTotop";

interface PageWrapperProps {
  children: ReactNode;
}

const PageWrapper: React.FC<PageWrapperProps> = ({ children }) => {
  return (
    <PageWrapperStyles>
      <ContentStyles>{children}</ContentStyles>
    </PageWrapperStyles>
  );
};

export default PageWrapper;
