import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { HeaderStyles } from "./styles";
import SearchBar from "../../components/searchBar/index";
import { useLocation } from "react-router-dom";
import {
  useLoginInfoContext,
  useAddressInfoContext,
  useOrdersInfoContext,
  useLoginContext,
  useAddressContext,
  useMobileNavBarContext,
} from "../../context/MyContext";
import useWindowSize from "../../hooks/useWindowSize/useWindowSize";

const Header: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { width, height } = useWindowSize();

  const [searchValue, setSearchValue] = useState<string>(""); // 헤더 검색창
  const [loginInfo, setLoginInfo] = useState<boolean>(false);

  const { isMobileNavBarTrue, setIsMobileNavBarTrue } =
    useMobileNavBarContext(); // 모바일 navBar Context API
  const { setIsUserOrdersInfo } = useOrdersInfoContext(); // 유저의 주문하기 페이지 정보 Context API
  const { setIsUserAddressInfo } = useAddressInfoContext(); // 유저의 주소 정보 Context API
  const { isLoggedIn, setIsLoggedIn } = useLoginInfoContext(); // 로그인 여부 Context
  const { setIsLoginTrue } = useLoginContext(); // 로그인 모달 context
  const { setIsAddressTrue } = useAddressContext(); // 주소 변경 모달 context

  const handleLoginBtnClick = () => {
    setIsLoginTrue(true);
  };

  const handleAddressBtnClick = () => {
    setIsAddressTrue(true);
  };

  // 로그아웃 버튼 함수
  const handleLogoutBtnClick = () => {
    const localLoginInfo = window.localStorage.getItem("loginInfo");
    const sessionLoginInfo = window.sessionStorage.getItem("loginInfo");

    if (localLoginInfo) {
      window.localStorage.clear(); // 로컬 스토리지 모든 항목 삭제
    } else if (sessionLoginInfo) {
      window.sessionStorage.clear(); // 세션 스토리지 모든 항목 삭제
    }
    setLoginInfo(false); //로그인 정보 초기화
    setIsLoggedIn(false); // 로그인 여부 Context API 초기화
    setIsUserOrdersInfo([]); // 주문하기 페이지 정보 Context API 초기화
    setIsUserAddressInfo([]); // 유저 주소 정보 Context API 초기화
    navigate("/"); // 메인 페이지로 이동
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  // searcBar 엔터키 눌렀을 시 상품 리스트 API 호출로 검색 기능
  const hadleSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault(); // 기본 엔터 동작 방지
      // useParams ? 를 이용하여 검색한 결과 상품 리스트 페이지로 이동
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      setLoginInfo(true);
    } else {
      setLoginInfo(false);
    }
  }, [isLoggedIn]);

  return (
    <div id="header">
      <HeaderStyles>
        <div className="leftContent">
          <div
            className="logo"
            onClick={() => {
              navigate("/");
            }}
          >
            <h1>
              <img
                src={`${process.env.PUBLIC_URL}/images/logo/logo.jpg`}
                alt="logo"
              />
            </h1>
          </div>
          {width <= 1024 ? (
            <div
              className="moMenuIcon"
              onClick={() => {
                setIsMobileNavBarTrue(!isMobileNavBarTrue);
              }}
            >
              <div className="icon">
                <FontAwesomeIcon icon={faAngleDown} />
              </div>
            </div>
          ) : null}
          {width > 1024 ? (
            <ul>
              <li
                className={
                  location.pathname === "/all-products" ? "active" : ""
                }
                onClick={() => {
                  // navigate("/all-products");
                  navigate(
                    `/all-products?page=1&refresh=${new Date().getTime()}`
                  );
                }}
              >
                전체상품
              </li>
              <li
                className={location.pathname === "/notice" ? "active" : ""}
                onClick={() => {
                  navigate("/notice");
                }}
              >
                공지사항
              </li>
              {/* <li
              className={location.pathname === "/order-way" ? "active" : ""}
              onClick={() => {
                navigate("/order-way");
              }}
            >
              주문방법
            </li> */}
            </ul>
          ) : null}
        </div>
        <div className="rightContent">
          <div className="subMemu">
            {loginInfo ? (
              <ul>
                <li
                  onClick={() => {
                    handleAddressBtnClick();
                  }}
                >
                  정보 변경
                </li>
                <li
                  onClick={() => {
                    handleLogoutBtnClick();
                  }}
                >
                  로그아웃
                </li>
                {width >= 768 ? (
                  <li
                    onClick={() => {
                      // navigate("/customer-service");
                      navigate(
                        `/customer-service?refresh=${new Date().getTime()}`
                      );
                    }}
                  >
                    고객센터
                  </li>
                ) : null}
              </ul>
            ) : (
              <ul>
                <li
                  onClick={() => {
                    handleLoginBtnClick();
                  }}
                >
                  로그인
                </li>

                <li
                  onClick={() => {
                    navigate("/customer-service");
                  }}
                >
                  고객센터
                </li>
              </ul>
            )}
          </div>
          {/* <SearchBar
            id="headerSearchBar"
            value={searchValue}
            onChange={handleSearchChange}
            onKeyDown={hadleSearchKeyDown}
          /> */}
          {/* <div className="searchBar">
            <div className="searchImg">
              <FontAwesomeIcon icon={faSearch} />
            </div>
            <input type="text" placeholder="검색" />
          </div> */}
        </div>
      </HeaderStyles>
    </div>
  );
};

export default Header;
