import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Header from "./header";
import Footer from "./footer";
import Navbar from "./navbar";
import PageWrapper from "./pageWrapper";
import Infobar from "./infobar";
import PrivateRoutes from "../routes/PrivateRouter/index";
import PublicRoutes from "../routes/PublicRouter/index";
import useWindowSize from "../hooks/useWindowSize/useWindowSize";
interface RouteConfig {
  path: string;
  name: string;
  component?: React.ComponentType;
}

interface LayoutProps {
  isLoggedIn: boolean;
}

const Layout: React.FC<LayoutProps> = ({ isLoggedIn }) => {
  const { width, height } = useWindowSize();
  // privateRoutes 정의: 로그인 상태에서 접근할 수 있는 라우트들
  const privateRoutes = PrivateRoutes.map(
    (route: RouteConfig, index: number) => {
      return route.component ? (
        <Route key={index} path={route.path} element={<route.component />} />
      ) : null;
    }
  );
  // publicRoutes 정의: 비로그인 상태에서 접근할 수 있는 라우트들
  const publicRoutes = PublicRoutes.map((route: RouteConfig, index: number) => {
    return route.component ? (
      <Route key={index} path={route.path} element={<route.component />} />
    ) : null;
  });

  return (
    <div>
      <Header />
      <Infobar />
      {(isLoggedIn || width < 768) && <Navbar />}{" "}
      {/*로그인 상태일 때 또는 width < 768 Navbar를 렌더링 */}
      <PageWrapper>
        <Routes>
          {/* 로그인 상태에 따라 다른 라우트 렌더링 */}
          {isLoggedIn ? (
            <>
              {/* 로그인된 사용자를 위한 privateRoutes 렌더링 */}
              {privateRoutes}
              {/* 정의된 라우트 외의 모든 경로는 홈으로 리디렉션 */}
              <Route path="*" element={<Navigate to="/" replace />} />
            </>
          ) : (
            <>
              {/* 비로그인 상태에서 접근 가능한 publicRoutes 렌더링 */}
              {publicRoutes}
              {/* 정의된 라우트 외의 모든 경로는 홈으로 리디렉션 */}
              <Route path="*" element={<Navigate to="/" replace />} />
            </>
          )}
        </Routes>
        {/* 로그인 상태일 때는 privateRoutes 아니라면 publicRoutes
        <Routes>{isLoggedIn ? privateRoutes : publicRoutes}</Routes> */}
      </PageWrapper>
      <Footer />
    </div>
  );
};

export default Layout;
