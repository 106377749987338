import styled from "styled-components";

export const NavbarStyles = styled.div`
  /* margin-top: 30px; */
  width: 250px;
  min-width: 250px;
  max-width: 307px;
  height: 100%;
  position: fixed;
  top: 94px;
  background-color: var(--color-background-primary);
  padding: 16px;
  border-right: 1px solid var(--color-background-secondary);
  z-index: 990;
  &.NavBar {
    display: block !important;
  }
  @media (max-width: 768px) {
    z-index: 993;
  }
  &.mobileNavBar {
    display: none;
    &.active {
      display: block;
    }
  }
  .navBarContentWrap {
    /* width: 275px; */
    height: 232px;
    ul {
      display: flex;
      gap: 8px;
      flex-direction: column;
      li {
        width: 220px;
        height: 40px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 12px;
        padding: 8px 12px;
        p {
          font-family: var(--font-family-primary);
          font-size: var(--font-size-md);
          font-weight: var(--font-weight-medium);
          line-height: 21px;
          color: var(--color-label-primary);
          @media (max-width: 768px) {
            font-size: var(--mo-font-size-md);
          }
        }
        &:hover {
          background-color: var(--color-background-secondary);
          border-radius: 10px;
          cursor: pointer;
        }
        &.active {
          background-color: var(--color-background-secondary);
          border-radius: 10px;
        }
      }
    }
    .DepositAccount {
      margin-top: 27px;
      text-align: center;
      font-size: var(--font-size-md);
      font-weight: var(--font-weight-bold);
      color: var(--color-logo-primary);
      border-width: 5px;
      border-style: solid;
      border-radius: 10px;
      border-color: var(--color-logo-primary);
      display: none;
      padding: 10px 0;
      small {
        font-size: var(--font-size-sm);
      }
      &.active {
        display: block;
      }
      @media (max-width: 768px) {
        margin-top: 5px;
        font-size: var(--mo-font-size-sm);
        small {
          font-size: var(--mo-font-size-xs);
        }
      }
    }
  }
`;
