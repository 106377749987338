import React from "react";

const Main = React.lazy(() => import("../../pages/Main/index"));

const SignUp = React.lazy(() => import("../../pages/Signup"));
const Notice = React.lazy(() => import("../../pages/Notice/index"));
const NoticeDetail = React.lazy(
  () => import("../../pages/Notice/Detail/index")
);
const Order = React.lazy(() => import("../../pages/Order/index"));
const ProductDetail = React.lazy(
  () => import("../../pages/ProductDetail/index")
);
const AllProducts = React.lazy(() => import("../../pages/AllProducts/index"));
const OrderMethod = React.lazy(() => import("../../pages/OrderMethod/index"));

const CustomerService = React.lazy(
  () => import("../../pages/CustomerService/index")
);

interface RouteConfig {
  path: string;
  name: string;
  component: React.ComponentType;
}

// PrivateRouter: 인증된 사용자만 접근할 수 있는 페이지에 대한 라우터
// PublicRouter: 인증되지 않은 사용자도 접근할 수 있는 페이지에 대한 라우터

const PublicRouter: RouteConfig[] = [
  {
    // 메인페이지
    path: "/",
    name: "Main",
    component: Main,
  },
  {
    // 회원가입
    path: "/signup",
    name: "SignUp",
    component: SignUp,
  },
  {
    // 공지사항
    path: "/notice",
    name: "Notice",
    component: Notice,
  },
  {
    // 공지사항 Detail 페이지
    path: "/notice/:id", // 동적 경로를 사용하여 id 매개변수를 포함시킴
    name: "NoticeDetail",
    component: NoticeDetail,
  },
  {
    // 전체 상품 페이지
    path: "/all-products",
    name: "AllProducts",
    component: AllProducts,
  },
  {
    // 상품 상세 페이지
    path: "/product-detail/:id", // 동적 경로를 사용하여 id 매개변수를 포함시킴
    name: "ProductDetail",
    component: ProductDetail,
  },
  {
    // 주문 방법 페이지
    path: "/order-way", // 동적 경로를 사용하여 id 매개변수를 포함시킴
    name: "OrderMethod",
    component: OrderMethod,
  },
  {
    // 고객센터 페이지
    path: "/customer-service", // 동적 경로를 사용하여 id 매개변수를 포함시킴
    name: "CustomerService",
    component: CustomerService,
  },
];

export default PublicRouter;
