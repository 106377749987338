import styled from "styled-components";

export const FooterStyles = styled.div`
  width: 100%;
  height: 200px;
  position: relative;
  bottom: 0;
  background-color: var(--color-background-primary);
  display: flex;
  justify-content: flex-start;
  border-top: 1px solid #e5e8eb;
  /* white-space: nowrap; */
  z-index: 991;
  .footerContentWrap {
    /* width: 1280px;
    height: 135%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 80px; */
    /* width: 960px; */
    width: 100%;
    /* height: 200px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin: 0 auto; */
    margin: 0 30px;
    .logoImage {
      width: fit-content;
      max-width: 250px;
      @media (max-width: 768px) {
        max-width: 150px;
      }
      h1 {
        padding: 10px 0;
        @media (max-width: 768px) {
          font-size: var(--mo-font-size-xl);
        }
        img {
          max-width: 100%;
          height: auto;
          display: flex;
          align-items: center;
        }
      }

      .copyright {
        font-size: var(--font-size-xs);
        font-family: var(--font-family-primary);
        font-weight: var(--font-weight-medium);
        color: var(--color-label-primary);
        @media (max-width: 768px) {
          font-size: var(--mo-font-size-xs);
        }
      }
    }
    .textBox {
      display: flex;
      flex-direction: column;
      gap: 4px;
      p {
        font-size: var(--font-size-sm);
        font-weight: var(--font-weight-medium);
        font-family: var(--font-family-primary);
        color: var(--color-label-primary);
        @media (max-width: 768px) {
          font-size: var(--mo-font-size-xs);
        }
      }
    }
  }
`;
