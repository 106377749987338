import { InfobarContainer } from "./styles";

const Infobar: React.FC = () => {
  return (
    <InfobarContainer>
      <div className="contentBox">
        <p>
          청과물 도매 공급 / 셀러들을 위한 과일여지도에 오신 것을 환영합니다.
        </p>
      </div>
    </InfobarContainer>
  );
};
export default Infobar;
